import React, { Component } from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Line} from 'rc-progress';
//import ReactPaginate from 'react-paginate';
import Moment from 'react-moment';

import {
    Card,
    Container,
    Row,
    Button,
    Col
} from 'reactstrap';

import Service from "../../services/Feedbacks/LearningPlansUserRating";
import {AlertContext} from "../../providers/AlertProvider";
import {range} from "../../core/ranges/ranges";

class FeedbacksDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listEduc: [],
            pages: 0,
            loading: false,
            invalid: false,
            showConfirmation: false,
            formFilter: {
                active: true,
                name: ''
            }
        }
    }

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to='/feedbacks' />
        }
    };

    renderStars = (selectedStars) => {
        const style = {
            color: 'gold'
        };
        return [...range(1, 5)].map((value, index) => {
            return <i className={`${((value <= selectedStars) ? "fas" : "far")} fa-star`}
                      style={style}/>
        });
    };

    renderFeedbacks = () => {
        if (!this.state.model){
            return;
        }
        const data = this.state.model.feedbacks;
        debugger
        return data.map((value, index) =>{
            return (
            <Row className="sg-feedback avaliacoes">
                <Col>
                    <i className="fas fa-user-circle"/>
                </Col>
                <Col md={11} className="mb-5">
                    <h3>{data[index].createdBy} <span><Moment date={data[index].createdDate} format='DD/MM/YYYY'/></span></h3>
                    {this.renderStars(data[index].ratingStars)}
                    <p>{data[index].description}</p>
                </Col>
            </Row>)
        });
    };

    componentDidMount() {
        
        const { match: { params } } = this.props;
        if (params.id !== undefined) {
            Service.getRatingLearningPlanId(params.id, (data) => {                                                
                this.setState({
                    id: params.id,
                    model:data
                });                
            }, (err) => {
                alert(err)
            })

        }else {
            this.setState({
                redirect: true
            })
        }
    }

    render() {
        const model = this.state.model;
        if (!model) {
            return <section className="container">
                <section className="sg-loading">
                    <div className="lds-ring">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <p>Carregando...</p>
                </section>
            </section>
        }

        const percentOne = (model.oneStarCount / model.votesCount) *100;
        const percentTwo = (model.twoStarsCount / model.votesCount) *100;;
        const percentThree = (model.threeStarsCount / model.votesCount) *100;;
        const percentFour = (model.fourStarsCount / model.votesCount) *100;;
        const percentFive = (model.fiveStarsCount / model.votesCount) *100;;

        return (
            <main className="h-100 animated fadeIn">
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                {this.renderRedirect()}
                <Container fluid className="h-100">
                <Row className="hold-title">
                        <Col md={11} className={`title-page`}>
                            <h2>Avaliações Roteiros de aprendizagem</h2>
                        </Col>
                        <Col md={1} className="hold-plus">
                            <Link className="bbtn">
                                <Button
                                    outline
                                    color="primary"
                                    block
                                    onClick={this.props.history.goBack}
                                    alt="Voltar"
                                    title="Voltar">
                                    <i className="fas fa-arrow-left" />
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Container>
                        <Card className="Feedback">
                            <Row>
                                <Col>{model.learningPlanName}</Col>
                                <Col>
                                    {this.renderStars(model.average)}
                                </Col>
                                <Col>{`${model.votesCount} votos`}</Col>
                            </Row>
                        </Card>
                    
                    <Row className="sg-progress sg-feedback mt-5">
                        <Col md={2}>
                            <h4>Feedback</h4>
                            <h3>{model.average}</h3>
                            {this.renderStars(model.average)}
                        </Col>
                        <Col md={10}>
                            <Row>
                                <Col d={12}>
                                    <Line percent={percentFive} strokeWidth="4" trailColor="#eee" trailWidth="4" strokeColor="orange" />
                                </Col>
                                <Col>
                                    {this.renderStars(5)} {percentFive}%
                               </Col>
                            </Row>
                            <Row>
                                <Col d={12}>
                                    <Line percent={percentFour} strokeWidth="4" trailColor="#eee" trailWidth="4" strokeColor="green" />
                                </Col>
                                <Col>
                                    {this.renderStars(4)} {percentFour}%
                               </Col>
                            </Row>
                            <Row>
                                <Col d={12}>
                                    <Line percent={percentThree} strokeWidth="4" trailColor="#eee" trailWidth="4" strokeColor="orange" />
                                </Col>
                                <Col>
                                    {this.renderStars(3)}  {percentThree}%
                               </Col>
                            </Row>
                            <Row>
                                <Col d={12}>
                                    <Line percent={percentTwo} strokeWidth="4" trailColor="#eee" trailWidth="4" strokeColor="orange" />
                                </Col>
                                <Col>
                                    {this.renderStars(2)} {percentTwo}%
                               </Col>
                            </Row>
                            <Row>
                                <Col d={12}>
                                    <Line percent={percentOne} strokeWidth="4" trailColor="#eee" trailWidth="4" strokeColor="orange" />
                                </Col>
                                <Col>
                                    {this.renderStars(1)} {percentOne}%
                               </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="sg-feedback">
                        <Col>
                            <h2>Avaliações</h2>
                            <span>{model.votesCount}</span>
                        </Col>
                    </Row>
                    
                    {this.renderFeedbacks()}
                    {/*<Row>
                        <Col md={12} className="sg-pagination">
                            <ReactPaginate className="pagination"
                                previousLabel={'anterior'}
                                nextLabel={'próximo'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={25}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        </Col>
                    </Row>*/}
                    </Container>
                </Container>
            </main>
        );
    }
}

export default withRouter(FeedbacksDetails);