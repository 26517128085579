// GLOBAL CONSTANTS
// -----------------------------------

export const STORAGE_KEYS = {
    JWT_KEY: 'learningPlan.jwt',
    JWT_REF_KEY: 'learningPlan.ref_jwt',
    JWT_EXP_KEY: 'learningPlan.exp_jwt',
    USER_NAME: 'learningPlan.full_user_name',
    USER_EMAIL: 'learningPlan.full_user_email'
};

export const URLS = {
    //LOCALHOST
    //API: "https://localhost:44397/api/",
    //REDIRECT: "http://localhost:44325/"

    //DEV
    //API: "https://appservice-roteiros-dev.azurewebsites.net/api/",
    //REDIRECT: "https://webapp-roteiros-dev.azurewebsites.net/"

    //PROD
    API: "https://appservice-roteiros-prod.azurewebsites.net/api/",
    REDIRECT: "https://roteiros-adm.bigbrain.com.br"

}

export const ADAL = {
    TENANT: "esatnet.onmicrosoft.com",
    CLIENT_ID: "fe3ee87f-1bef-4a74-867c-70d4cb403103",
    CLIENT_SECRET: "b91h2iO9Fz.4Rm1._E8NMJWa9-Rny.VaY~"
}

export default {
    STORAGE_KEYS,
    URLS,
    ADAL
}