import React, { Component } from 'react';
import { Alert, Button, Card, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import DropZone from './../Dropzone/Dropzone'
import Progress from '../Common/Progress'

import '../../assets/css/upload.css';
import AADService from "../../services/AADService";

class Upload extends Component {
    constructor(props) {
        super(props);
        this.uploadChild = React.createRef();
        //this.uploadFiles = React.createRef();

        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfulUploaded: false,
            uploadUrl: this.props.uplaodUrl
        };

        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
    }

    onFilesAdded(files) {
        this.setState(prevState => ({
            files: prevState.files.concat(files)
        }))
    }

    renderProgress = (file) => {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfulUploaded) {
            return (
                <div className={"progressWrapper"}>
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                    <img className={"checkIcon"} alt={"done"} src={"./check_circle_outline-24px.svg"}
                        style={{ opacity: uploadProgress && uploadProgress.state === "done" ? 0.5 : 0 }} />
                </div>
            )
        }
    };

    async uploadFiles() {        
        console.log(this.state)
        this.setState({ uploadProgress: {}, uploading: true });
        const promises = [];
        this.state.files.forEach(file => {
            promises.push(this.sendRequest(file));
        });
        try {
            await Promise.all(promises);
            this.setState({ successfulUploaded: true, uploading: false });
        } catch (e) {
            // Not Production ready! Do some error handling here instead...
            this.setState({ successfulUploaded: true, uploading: false });
        }
    }

    deleteThumb() {
    
        this.setState({
            files: []
        });
        
    }

    sendRequest(file) {

        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.upload.addEventListener("progress", event => {
                if (event.lengthComputable) {
                    const copy = { ...this.state.uploadProgress };
                    copy[file.name] = {
                        state: "pending",
                        percentage: (event.loaded / event.total) * 100
                    };
                    this.setState({ uploadProgress: copy });
                }
            });

            req.upload.addEventListener("load", () => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "done", percentage: 100 };
                this.setState({ uploadProgress: copy });
                resolve(req.response);
            });

            req.upload.addEventListener("error", () => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({ uploadProgress: copy });
                reject(req.response);
            });

            const formData = new FormData();
            formData.append("file", file, file.name);

            let aadService = new AADService();

            req.open("POST", this.state.uploadUrl);

            if (aadService.getToken() !== '') {
                req.setRequestHeader('Authorization', `Bearer ${aadService.getToken()}`);
            }
            req.send(formData);
        });
    }
     

    render() {
        const style = {   
        display: "block",
        flexDirection: "column",
        alignItems: "flex-start",
        textAlign: "left",
        marginTop: "-15px",
        marginBottom: "0px",
        }
        const content = {   
        display: "block"
        }
        return (
            <div className="upload" style={style}>
                <div className="upload-content" style={content}>
                    <div>
                        <DropZone
                            onFilesAdded={this.onFilesAdded}/>
                    </div>                    
                </div>
            </div>
        )
    }
}

export default Upload;