import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

// https://www.npmjs.com/package/react-table
import "react-table/react-table.css";

import {
    Card,
    Container,
    Row,
    FormGroup,
    Label,
    Input,
    Button,
    Col, Form
} from 'reactstrap';

import Service from "../../services/Feedbacks/LearningPlansUserRating";
import {AlertContext} from "../../providers/AlertProvider";
import {range} from "../../core/ranges/ranges";

class Feedbacks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listRatings: [],
            pages: 0,
            loading: false,
            invalid: false,
            showConfirmation: false,
            formFilter: {
                active: true,
                name: ''
            }
        }
    }

    handleFilterSubmit = e => {
        e.preventDefault();
        try {
            Service.search(this.state.formFilter, (data) => {
                if (data) {
                    this.setState({
                        listRatings: data,
                        pages: data.pages,
                        loading: false
                    });
                } else {
                    this.setState({
                        listRatings: data,
                        pages: 0,
                        loading: false
                    });
                }
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            });
        } catch (e) {
            this.showErrorMessage(e.message);
        }
    };

    renderStars = (selectedStars) => {
        const style = {
            color: 'gold'
        };
        return [...range(1, 5)].map((value, index) => {
            return <i className={`${((value <= selectedStars) ? "fas" : "far")} fa-star`}
                      style={style}/>
        });
    };

    handleInputChange(target) {
        const value = target.value;
        let filter = this.state.formFilter;

        if (target.id === 'statusSelect') {
            filter.active = (value == 'true')
            this.setState({
                filter
            })
        }
        if (target.id === 'name') {
            filter.name = value
            this.setState({
                filter
            })
        }
    }

    resetFilter = () => {
        let filter = this.state.formFilter;
        filter.name = '';
        filter.active = true;

        this.setState({ filter })
        this.loadData({ page: 0, pageSize: 1000 });
    }

    handleInputBlur = target => {
        const field = target.name;
        this.setState({
            invalid: {...this.state.invalid, [field]: target.required && !this.state.model[field]},
        });
    };

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        this.setState({loading: true});        
        Service.getData(0, 1000, (data) => {
            this.setState({
                listRatings: data,
                pages: data.pages,
                loading: false
            }); 
        });
    }

    renderLearningPlanFeedback = () => {
        
        const listRatings = this.state.listRatings;
        return listRatings.map((plan, index) => {
            let average = plan.ratingStars;
            debugger
            return (
                <Link to={`/feedbacks-details/${plan.learningPlansId}`}>                    
                        <section>
                            <Row>
                                <Col md={2}>{plan.name}</Col>
                                <Col md={2}>
                                    {this.renderStars(average)}
                                </Col>
                                <Col md={2}>{`${plan.ratingStars} votos`}</Col>
                                <Col md={5}>
                                    <i className="fas fa-comment-dots"/>
                                    {`${plan.description} - comentários`}
                                </Col>
                                <Col md={1}>
                                    <i className="fas fa-chevron-right"/>
                                </Col>
                            </Row>
                        </section>                        
                </Link>
            )
        });
    };

    render() {
        const listRatings = this.state.listRatings;        
        if (!listRatings) {
            return <section className="container">
                <section className="sg-loading">
                    <div className="lds-ring">
                        <div/>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                    <p>Carregando...</p>
                </section>
            </section>
        }

        return (
            <main className="h-100 animated fadeIn">
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>
                <Container fluid className="h-100">
                   
                        <Col md={11} className={`title-page`}>
                            <h2>Feedbacks</h2>
                        </Col>
                    
                        <Col>                           
                                <Form onSubmit={this.handleFilterSubmit}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="name">Tema/título</Label>
                                                <Input
                                                    value={this.state.formFilter.name}
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    placeholder="Roteiro de Aprendizagem"
                                                    onChange={({target}) => this.handleInputChange(target)}
                                                    onBlur={({target}) => this.handleInputBlur(target)}
                                                    invalid={this.state.invalid.name}/>
                                            </FormGroup>
                                        </Col>
                                        {/* <Col md={4}>
                                            <FormGroup>
                                                <Label for="Status">Status</Label>
                                                <Input type="select" name="active" id="statusSelect"
                                                       value={this.state.formFilter.active}
                                                       onChange={({target}) => this.handleInputChange(target)}
                                                       onBlur={({target}) => this.handleInputBlur(target)}>
                                                    <option value={true}>Ativo</option>
                                                    <option value={false}>Inativo</option>
                                                </Input>
                                            </FormGroup>
                                        </Col> */}
                                    </Row>
                                    <Row className="hold-buttons">
                                        <Col>
                                            <Button id={"btnSave"}
                                                    color="primary" type="submit">
                                                <span>Pesquisar</span>
                                            </Button>
                                            <Button
                                                id={"btnReset"}
                                                color="primary"
                                                onClick={this.resetFilter}
                                            >
                                                <span>Limpar</span>
                                            </Button>
                                        </Col>
                                     </Row>
                                </Form>
                        </Col>
                        <div className="Feedback">
                            {this.renderLearningPlanFeedback()}
                        </div>
                </Container>
            </main>
        );
    }
}

export default withRouter(Feedbacks);