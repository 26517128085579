import BaseService from '../Base/BaseServices'
import Config from '../../core/constants';
import HttpUtil from '../../core/network/httpUtil';

let Base = new BaseService('LearningSteps');

class LearningStepsServices {
    constructor(){
    }

    static services = Base.services;

    static getData(page, pageSize, cb, errorCb) {
        return this.services.getData(page, pageSize, cb, errorCb);
    }
    static updateData(id, data, cb, errorCb){
        return this.services.updateData(id, data, cb, errorCb);
    }
    static removeData(id, cb, errorCb){
        return this.services.removeData(id, cb, errorCb);
    }
    static saveData(data, cb, errorCb) {
        return this.services.saveData(data, cb, errorCb);
    }
    static getDetails(id, cb, errorCb) {
        return this.services.getDetails(id, cb, errorCb);
    }
    static search(data, cb, errorCb){
        if (data.hasOwnProperty('active')){
            if (typeof data.active !== "boolean"){
                data.active = data.active === 'true';
            }
        }
        data.removed = false;
        return this.services.search(data, cb, errorCb);
    }
    static getLearningStepsForDisciplines = (cb, errorCb) => {
        let urlApi = process.env.PUBLIC_URL || Config.URLS.API;
        
        return HttpUtil.GET(`${urlApi}LearningSteps/GetLearningStepsForDisciplines`,
                    response => cb(response.data),
                errorCb);
    };
    static getLearningStepsForExperienceFields = (cb, errorCb) => {
        let urlApi = process.env.PUBLIC_URL || Config.URLS.API;
        
        return HttpUtil.GET(`${urlApi}LearningSteps/GetLearningStepsForExperienceFields`,
                    response => cb(response.data),
                errorCb);
    };
}

export default LearningStepsServices;