import React, { Component} from 'react';

import '../../assets/css/dropzone.css';

class DropZone extends Component {
    constructor(props) {
        super(props);
        this.state = {highlight: false};

        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);

        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }

    fileListToArray(list) {
        const array = [];
        for (let i = 0; i < list.length; i++) {
            array.push(list.item(i));
        }
        return array;
    }

    openFileDialog() {
        if (this.props.disabled) return;
        //this.fileInputRef.current.click();
    }

    onFilesAdded(evt) {
        if (this.props.disabled) return;
        const files = evt.target.files;
        
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);

            console.log(array);
        }        
    }

    onDragOver(evt) {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({ highlight: true });
    }

    onDragLeave() {
        this.setState({ highlight: false });
    }

    onDrop(event) {
        event.preventDefault();

        if (this.props.disabled) return;

        const files = event.dataTransfer.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
        this.setState({ highlight: false });
    }

    render() {
        return (
            <div className={`dropzone ${this.state.highlight ? "highlight" : ""}`}
                 onDragOver={this.onDragOver}
                 onDragLeave={this.onDragLeave}
                 onDrop={this.onDrop}
                 onClick={this.openFileDialog}
                 style={{ cursor: this.props.disabled ? "default" : "pointer" }}
            >
                <span alt="upload">Anexos</span>
                <input
                    ref={this.fileInputRef}
                    
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                />
            </div>
        );
    }
}
export default DropZone;