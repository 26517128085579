import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import Service from '../../services/LearningSteps/LearningStepsServices';

import { AlertContext } from '../../providers/AlertProvider'
import AADService from '../../services/AADService'

class LearningStepsForm extends Component {
    constructor(props) {
        super(props);

        this.AzureADService = new AADService();
        this.UserInfo = this.AzureADService.getUser();

        this.state = {
            successMsg: '',
            errorMsg: '',
            tipo: '',
            styleActive: "none",
            active: '',
            subLevel:'',
            model: {
                name: '',
                id: '',
                createdBy: '',
                modifiedBy: ''
            }
        }
    }

    handleClick = () => {
        this.props.history.push("/etapas-de-ensino");
    }


    handleInputChange(target) {

        const value = target.value;
        const field = target.name;
        this.setState({
            model: { ...this.state.model, [field]: value },
            invalid: { ...this.state.invalid, [field]: target.required && !value },
        });
        if (target.id === 'statusSelect') {
            this.setState({
                active: value
            })
        }
        if (target.id === 'subLevelSelect') {
            console.log("sublevel")
            this.setState({
                subLevel: parseInt(value)
            })
        }
    }

    componentDidMount() {
        
        const { match: { params } } = this.props;
        let context = this;

        if (params.id !== undefined) {
            Service.getDetails(params.id, (data) => {
                let ativo = data.active === true ? "1" : "0";
                context.setState({
                    id: params.id,
                    styleActive: "flex",
                    tipo: 'Editar Etapas de ensino',
                    active: ativo,
                    subLevel: data.subLevel,
                    model: data
                });
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            })
        } else {
            let newModel = this.state.model;
            newModel.name = '';
            newModel.modifiedBy = '';
            newModel.createdBy = '';

            this.setState({
                tipo: 'Adicionar Etapa de ensino',
                model: newModel
            })
        }
    };

    handleSubmit = e => { 

        e.preventDefault();
        let isUpdate = this.state.id !== undefined;
                 
        try {
            if (isUpdate) {
                
                const { match: { params } } = this.props;
                
                let dado = { 
                    "id": params.id, 
                    "name": this.state.model.name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name, 
                    "active": (this.state.active === "1" ? true : false),
                    "subLevel": this.state.subLevel
                }

                console.log(dado)
                
                Service.updateData(params.id, dado, (data) => {
                    let newModel = this.state.model;
                    newModel.name = this.state.model.name;
                    newModel.modifiedBy = this.state.model.name;
                    newModel.id = params.id;
                    this.showSuccessMessage("Etapa de ensino salva com sucesso!");
                    this.handleClick();
                    this.setState({
                        model: newModel
                    });
                    
                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                })
            } else {
                
                let novodado = { 
                    "name": this.state.model.name, 
                    "createdBy": this.UserInfo.profile.name, 
                    "createdByEmail": this.UserInfo.profile.unique_name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name,
                    "active": (this.state.active === "1" ? true : false),
                    "subLevel": this.state.subLevel
                }

                console.log(novodado)

                
                Service.saveData(novodado, (data) => {
                    console.log(this.UserInfo.profile.unique_name)
                    this.showSuccessMessage("Etapa de ensino salva com sucesso!");
                    let newModel = this.state.model;
                    newModel.name = this.state.model.name;
                    newModel.modifiedBy = this.state.model.name;
                    newModel.createdBy = this.state.model.createdBy;

                    this.handleClick();
                    this.setState({
                        model: newModel
                    });

                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                });
            }
        } catch (e) {
            this.showErrorMessage(e.message || e.response.data.Error);
        }
    };

    updateInputValue(evt) {
        this.setState({
            model: evt.target.value
        })
    };


    render() {

        let showSuccess = this.state.successMsg.length > 0;
        let showError = this.state.errorMsg.length > 0;

        return (
            <main className="h-100 animated fadeIn">

                <AlertContext.Consumer>
                    {(value) => {
                        this.showSuccessMessage = value.success;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>

                <Container fluid className="h-100">
                    {showSuccess && <Alert color={'success'}>{this.state.successMsg}</Alert>}
                    {showError && <Alert color={'success'}>{this.state.errorMsg}</Alert>}

                    <section className={`title-page mt-3`}>
                        <h2>{this.state.tipo}</h2>
                    </section>

                    <Col>                        
                        <Form onSubmit={this.handleSubmit}>
                           <Row>
                               <Col md={4}>
                                <Label for="name">Etapa de ensino</Label>
                                    <Input
                                        value={this.state.model.name}
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        placeholder="Etapa de ensino"
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                               </Col>
                               <Col md={4}>
                                    <Label for="Status">Status</Label>
                                    <Input 
                                        type="select" 
                                        name="active" 
                                        id="statusSelect"
                                        value={this.state.active}
                                        required    
                                        onChange={({target}) => this.handleInputChange(target)}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value={"1"}>Ativo</option>
                                        <option value={"0"}>Inativo</option>
                                    </Input> 
                               </Col>
                               <Col md={4}>
                                    <Label for="subLevel">Subníveis</Label>
                                    <Input 
                                        type="select" 
                                        name="active" 
                                        id="subLevelSelect"
                                        value={this.state.subLevel}    
                                        onChange={({target}) => this.handleInputChange(target)}
                                        required
                                    >
                                        <option value="">Selecione...</option>
                                        <option value={1}>Campos de Experiencia</option>
                                        <option value={2}>Áreas do Conhecimento e Disciplinas</option>
                                    </Input> 
                               </Col>
                           </Row> 
                           <section className="text-right">
                                <Button id={"btnSave"}
                                    color="primary" type="submit">
                                    <span>Salvar</span>
                                </Button>{' '}
                                <Link to={`/etapas-de-ensino`}> <Button color="primary">Cancelar</Button> </Link>
                            </section>                             
                        </Form>
                    </Col>
                </Container>
            </main>
        );
    }
}

export default withRouter(LearningStepsForm);