import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import { Editor } from '@tinymce/tinymce-react';

import Config from '../../core/constants';
import Service from '../../services/LearningPlans/LearningPlansServices';
import ServiceLearningSteps from '../../services/LearningSteps/LearningStepsServices';
import ServiceDigitalTools from '../../services/DigitalTools/DigitalToolsServices';
import ServiceSkillCompetences from '../../services/SkillCompetences/SkillCompetencesServices';

import { AlertContext } from '../../providers/AlertProvider'
import Upload from './../Dropzone/Upload';
import AADService from '../../services/AADService'

class LearningPlansForm extends Component {
    constructor(props) {
        super(props);
        this.multiselectRef = React.createRef();
        this.uploadChild = React.createRef();
        this.AzureADService = new AADService();
        this.UserInfo = this.AzureADService.getUser();

        this.state = {
            successMsg: '',
            errorMsg: '',
            tipo: '',              
            page: 0,
            pageSize: 100,
            id: '',
            name: '',
            active: '',
            duration: '',
            searchKey: '',
            learningStepId: '',
            subLevel: 0,

            listLearningSteps: [],
            listDigitalTools: [],
            listSkillCompetences: [],
            listSkillCompetencesExperienceFields: [],
            listLearningPlans: [],
            

            generalObjectives: '',
            specificObjectives: '',
            didacticResources: '',
            assessment: '',
            learningStrategies: '',
            references: '',

            learningPlansDigitalToolsIds: [],
            learningPlansSkillCompetencesIds: [],
            learningPlansRelatedsIds: [],
            learningPlansAttachments: [], 
            listLearningPlansRelateds: [],

            selectedValuesDigitalTools: [],
            selectedValuesKnowledgeAreas: [],
            selectedValuesDisciplines: [],            
            selectedValuesSkillCompetences: [],
            
        }
    }

    getDigitalTools = (state) => {
        
        this.setState({ loading: true });
        ServiceDigitalTools.getData(state.page, state.pageSize, (data) => {
            this.setState({
                listDigitalTools: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    getLearningSteps = (state) => {
        
        this.setState({ loading: true });

        ServiceSkillCompetences.getLearningStepsWithSkillCompetences((data) => {
            this.setState({
                listLearningSteps: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    getLearningPlans = (state) => {
        
        this.setState({ loading: true });
        Service.getData(state.page, state.pageSize, (data) => {
            this.setState({
                listLearningPlans: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };


    getSkillCompetencesDisciplinesByLearningStepId = (id, state) => {
        
        this.setState({ loading: true });
        ServiceSkillCompetences.getSkillCompetencesDisciplinesByLearningStepId(id, (data) => {
            this.setState({
                listSkillCompetences: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    getSkillCompetencesExperienceFieldsByLearningStepId = (id, state) => {
        
        this.setState({ loading: true });
        ServiceSkillCompetences.getSkillCompetencesExperienceFieldsByLearningStepId(id, (data) => {
            this.setState({
                listSkillCompetencesExperienceFields: data,
                pages: data.pages,
                loading: false
            });
            
        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    uploadAttchments = () => {

        this.uploadChild.current.state.uploadUrl = `${Config.URLS.API}LearningPlansAttachments/UploadFiles/${this.state.id}`;
        this.uploadChild.current.uploadFiles();
    }

    deleteAttachment = (attachmentId) => {
        
        this.setState({ loading: true });
        Service.deleteAttachment(attachmentId, (data) => {

            let Attachments = this.state.learningPlansAttachments;
            let newListAttachments = Attachments.filter(item => item.id != attachmentId);
            this.setState({
                learningPlansAttachments : newListAttachments
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
        
    }

    resetValues = () => {
        this.multiselectRef.current.resetSelectedValues();
    }

    handleClick = () => {
        this.props.history.push("/roteiros-de-aprendizagem");
    }   

    handleInputChange(target) {

        const value = target.value;
        const field = target.name;

        if (target.id === 'name') {
            this.setState({
                name: value
            })
        }
        if (target.id === 'statusSelect') {
            this.setState({
                active: value
            })
        }
        if (target.id === 'duration') {
            this.setState({
                duration: value
            })
        }
        if (target.id === 'searchKey') {
            this.setState({
                searchKey: value
            })
        }
        if (target.id === 'learningSteps') { 
   
            let subLevel = 0;
            if(target.value != ""){

                if(this.state.listLearningSteps.length > 0){
                    subLevel = this.state.listLearningSteps.find(x => x.id == target.value).subLevel;
                }
            
                this.setState({
                    learningStepId: value,
                    subLevel: subLevel,                
                    learningPlansSkillCompetencesIds: []
                })
                
                if(subLevel == 1){
                    this.getSkillCompetencesExperienceFieldsByLearningStepId(target.value);
                }
                if(subLevel == 2){
                    this.getSkillCompetencesDisciplinesByLearningStepId(target.value);
                }

            }else{
                this.setState({
                    learningStepId: value,
                    subLevel: subLevel,                
                    listSkillCompetences: [],
                    listSkillCompetencesExperienceFields: []
                })
            }
        }
        if (target.id === 'generalObjectives') {
            this.setState({
                generalObjectives: value
            })
        }
        if (target.id === 'specificObjectives') {
            this.setState({
                specificObjectives: value
            })
        }
        if (target.id === 'didacticResources') {
            this.setState({
                didacticResources: value
            })
        }
        if (target.id === 'assessment') {
            this.setState({
                assessment: value
            })
        }
        if (target.id === 'learningStrategies') {
            this.setState({
                learningStrategies: value
            })
        }
        if (target.id === 'references') {
            this.setState({
                references: value
            })
        }
    }

    handleEditorChange = (content, editor) => {
        this.setState({
			learningStrategies: content            
        })
      }

    onSelectDigitalTools = (parans, target) => {

        let digitalToolsId = parans.id
        let digitalTools = this.state.learningPlansDigitalToolsIds;

        if(target.checked != false){                    
            digitalTools.push(digitalToolsId);
            this.setState({
                learningPlansDigitalToolsIds : digitalTools
            });        
        }
        else{            
            var indice = digitalTools.indexOf(digitalToolsId);        
            digitalTools.splice(indice, 1);
            this.setState({
                learningPlansDigitalToolsIds : digitalTools
            });
        }    
    }

    onSelectSkillCompetencesExperienceFields = (itemId, target) => {

        let skillCompetencesId = itemId
        let skillCompetences = this.state.learningPlansSkillCompetencesIds;

        if(target.checked != false){ 
            skillCompetences.push(skillCompetencesId);
            this.setState({
                learningPlansSkillCompetencesIds : skillCompetences
            });    
        }
        else{
            var indice = skillCompetences.indexOf(skillCompetencesId);        
            skillCompetences.splice(indice, 1);
            this.setState({
                learningPlansSkillCompetencesIds : skillCompetences
            });
        } 
    }

    onSelectSkillCompetences = (knowledgeAreaId, itemId, target) => {
        
        let skillCompetencesId = itemId       
        let skillCompetences = this.state.learningPlansSkillCompetencesIds;

        if(target.checked != false){             
            skillCompetences.push(skillCompetencesId);
            this.setState({
                learningPlansSkillCompetencesIds : skillCompetences
            });        
        }
        else{          
            var indice = skillCompetences.indexOf(skillCompetencesId);        
            skillCompetences.splice(indice, 1);
            this.setState({
                learningPlansSkillCompetencesIds : skillCompetences
            });
        }    
    }

    onSelectRelatedPlans = (parans, target) => {
    
        let relatedsId = parans.id
        let relateds = this.state.learningPlansRelatedsIds;

        if(target.checked != false){                    
            relateds.push(relatedsId);
            this.setState({
                learningPlansRelatedsIds : relateds
            });        
        }
        else{            
            var indice = relateds.indexOf(relatedsId);        
            relateds.splice(indice, 1);
            this.setState({
                learningPlansRelatedsIds : relateds
            });
        }    
    }

    onRemoveRelatedPlans = (selectedList, selectedItem) => {

        let relateds = this.state.learningPlansRelatedsIds;
        var indice = relateds.indexOf(selectedItem);        
        relateds.splice(indice, 1);
        this.setState({
            learningPlansRelatedsIds : relateds
        });
    }

    componentDidMount() {
        
        const { match: { params } } = this.props;
        
        this.getLearningSteps(this.state);

        if (params.id !== undefined) {
            Service.getDetails(params.id, (data) => {
                let ativo = data.active === true ? "1" : "0";
                this.setState({
                    id: params.id,
                    tipo: 'Editar Roteiro de aprendizagem',                    
                    name: data.name,
                    active: ativo,
                    duration: data.duration,
                    searchKey: data.searchKey,
                    learningPlansDigitalToolsIds: data.learningPlansDigitalToolsIds,
                    learningStepId: data.learningStepId,
                    learningPlansSkillCompetencesIds: data.learningPlansSkillCompetencesIds,
                    generalObjectives: data.generalObjectives,
                    specificObjectives: data.specificObjectives,
                    didacticResources: data.didacticResources,
                    assessment: data.assessment,
                    learningStrategies: data.learningStrategies,
                    references: data.references,
                    learningPlansRelatedsIds: data.learningPlansRelatedsIds,
                    learningPlansSkillCompetences: data.learningPlansSkillCompetences.skillCompetences,
                    learningPlansAttachments : data.learningPlansAttachments
                });

                let digitalTools = [];
                let skillCompetences = [];
                let relateds = [];

                for(var item in data.learningPlansDigitalTools){
                    let digitalToolsItem = data.learningPlansDigitalTools[item].digitalTools;        
                    digitalTools.push(digitalToolsItem);
                }

                for(var item in data.learningPlansSkillCompetences){
                    let skillCompetencesItem = data.learningPlansSkillCompetences[item].skillCompetences;        
                    skillCompetences.push(skillCompetencesItem);
                }

                for(var item in data.learningPlansRelateds){
                    let relatedsItem = data.learningPlansRelateds[item].learningPlans;        
                    relateds.push(relatedsItem);
                }

                this.setState({
                    selectedValuesDigitalTools : digitalTools,
                    selectedValuesSkillCompetences : skillCompetences,
                    selectedValuesRelatedPlans: relateds
                });  

                
                ServiceLearningSteps.getDetails(data.learningStepId, (data) => {

                    let subLevel = data.subLevel;

                    this.setState({
                        subLevel : subLevel,
                    });  

                    if(subLevel == 1){
                        this.getSkillCompetencesExperienceFieldsByLearningStepId(data.id);
                    }else{
                        this.getSkillCompetencesDisciplinesByLearningStepId(data.id);
                    }
                }, (err) => {
                    //this.showErrorMessage(err.message || err.response.data.Error);
                })
                
              
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            })
        } else {
            this.setState({
                tipo: 'Adicionar Roteiro de aprendizagem',
            })
        }

        this.getDigitalTools(this.state);       
        this.getLearningPlans(this.state);
        
    };

    handleSubmit = e => { 
        
        e.preventDefault();
        let isUpdate = this.state.id !== "";
                 
        try {
            if (isUpdate) {
                
                const { match: { params } } = this.props;
                
                let dado = { 
                    "id": params.id, 
                    "name": this.state.name, 
                    "createdBy": this.UserInfo.profile.name, 
                    "createdByEmail": this.UserInfo.profile.unique_name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name,
                    "active": (this.state.active === "1" ? true : false),
                    "duration": this.state.duration,
                    "searchKey": this.state.searchKey,
                    "learningStepId": this.state.learningStepId,
                    "generalObjectives": this.state.generalObjectives,
                    "specificObjectives": this.state.specificObjectives,
                    "didacticResources": this.state.didacticResources,
                    "assessment": this.state.assessment,
                    "learningStrategies": this.state.learningStrategies,
                    "references": this.state.references,
                    "learningPlansDigitalToolsIds": this.state.learningPlansDigitalToolsIds,
                    "learningPlansSkillCompetencesIds": this.state.learningPlansSkillCompetencesIds,
                    "learningPlansRelatedsIds": this.state.learningPlansRelatedsIds
                }
                
                Service.updateData(params.id, dado, (data) => {

                    this.uploadAttchments();

                    this.showSuccessMessage("Roteiro de aprendizagem salvo com sucesso!");
                    this.handleClick();
                    
                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                })
            } else {
                
                let novodado = { 
                    "name": this.state.name, 
                    "createdBy": this.UserInfo.profile.name, 
                    "createdByEmail": this.UserInfo.profile.unique_name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name,
                    "active": (this.state.active === "1" ? true : false),
                    "duration": this.state.duration,
                    "searchKey": this.state.searchKey,
                    "learningStepId": this.state.learningStepId,
                    "generalObjectives": this.state.generalObjectives,
                    "specificObjectives": this.state.specificObjectives,
                    "didacticResources": this.state.didacticResources,
                    "assessment": this.state.assessment,
                    "learningStrategies": this.state.learningStrategies,
                    "references": this.state.references,
                    "learningPlansDigitalToolsIds": this.state.learningPlansDigitalToolsIds,
                    "learningPlansSkillCompetencesIds": this.state.learningPlansSkillCompetencesIds,
                    "learningPlansRelatedsIds": this.state.learningPlansRelatedsIds
                }

                Service.saveData(novodado, (data) => {     
                    
                    this.setState({
                        id : data.id
                    });  

                    this.uploadAttchments();

                    this.showSuccessMessage("Roteiro de aprendizagem salvo com sucesso!");
                    this.handleClick();
                    
                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                });
            }
        } catch (e) {
            this.showErrorMessage(e.message || e.response.data.Error);
        }
    };

    updateInputValue(evt) {
        this.setState({
            model: evt.target.value
        })
    };

    isSelectedDigitalTools = (id) =>{
        
        let selected = this.state.learningPlansDigitalToolsIds.filter((item) => {
            return item === id
        })

        if(selected.length >0){
            return'true'
        }
        return ''
    }

    isRequiredDigitalTools = () => {
        
        let selectedsDigitalTools = this.state.learningPlansDigitalToolsIds

        if(selectedsDigitalTools.length == 0 ){
            return {
                 required: "required"
                };
        }
        return ''
    }

    isSelectedSkillCompetences = (id) =>{
        
        let selected = this.state.learningPlansSkillCompetencesIds.filter((item) => {
            return item === id
        })

        if(selected.length >0){
            return'true'
        }
        return ''
    }

    isSelectedSkillCompetencesExperienceFields = (id) =>{
        
        let selected = this.state.learningPlansSkillCompetencesIds.filter((item) => {
            return item === id
        })

        if(selected.length >0){
            return'true'
        }
        return ''
    }


    isRequiredSkillCompetences = () => {
        
        let selectedsSkillCompetences = this.state.learningPlansSkillCompetencesIds

        if(selectedsSkillCompetences.length == 0 ){
            return {
                 required: "required"
                };
        }
        return ''
    }

    isRequiredSkillCompetencesExperienceFields = () => {
        
        let selectedsSkillCompetences = this.state.learningPlansSkillCompetencesIds

        if(selectedsSkillCompetences.length == 0 ){
            return {
                 required: "required"
                };
        }
        return ''
    }

    isSelectedLearninPlans = (id) =>{
        
        let selected = this.state.learningPlansRelatedsIds.filter((item) => {
            return item === id
        })

        if(selected.length >0){
            return'true'
        }
        return ''
    }

    isSelectedExperienceFields = (experienceField) => {
        
        let idExp = experienceField.id;
        let skillCompetences = experienceField.listSkillCompetences;
        let skillCompetencesCount = experienceField.listSkillCompetences.length;
        let skillCompetencesSelectedCount = 0;

        for(var itemSkill in skillCompetences){
            var idSkill = skillCompetences[itemSkill].id+'|'+idExp;
            var existe = this.state.learningPlansSkillCompetencesIds.includes(idSkill)
            if(existe)
            skillCompetencesSelectedCount++;
        }

        if(skillCompetencesCount === skillCompetencesSelectedCount){
            return 'true'
        }
        return ''
    }

    isSelectedknowledgeArea = (knowledgeArea) => {


        let selected = this.state.selectedValuesKnowledgeAreas.filter((item) => {
            return item === knowledgeArea.knowledgeAreaId
        })

        if(selected.length >0){
            return'true'
        }
        return ''
    }

    isSelectedDisciplines = (discipline) =>{

        let idDisc = discipline.id;
        let skillCompetences = discipline.skillCompetences;
        let skillCompetencesCount = discipline.skillCompetences.length;
        let skillCompetencesSelectedCount = 0;

        for(var itemSkill in skillCompetences){
            var idSkill = skillCompetences[itemSkill].id+'|'+idDisc;
            var existe = this.state.learningPlansSkillCompetencesIds.includes(idSkill)
            if(existe)
            skillCompetencesSelectedCount++;
        }

        if(skillCompetencesCount === skillCompetencesSelectedCount){
            return 'true'
        }
        return ''
    }

    selectAllDisciplines (parans, target) {
        
        let disciplines = parans.disciplines;
        let KnowledgeAreasIds = this.state.selectedValuesKnowledgeAreas;
        let disciplinesIds = this.state.selectedValuesDisciplines;
        let skillCompetencesIds = this.state.learningPlansSkillCompetencesIds;
        
        if(target.checked != false){ 

            for(var item in disciplines){

                //KnowledgeAreas
                var idKnow = disciplines[item].knowledgeArea;
                KnowledgeAreasIds.push(idKnow);

                //Disciplines
                var idDisc = disciplines[item].id;
                disciplinesIds.push(idDisc);

                //SkillCompetences
                var skillCompetences = disciplines[item].skillCompetences                
                for(var itemSkill in skillCompetences){
                    var idSkill = skillCompetences[itemSkill].id+'|'+idDisc;
                    skillCompetencesIds.push(idSkill);
                }               
            }

            this.setState({
                //selectedValuesKnowledgeAreas : KnowledgeAreasIds,
                selectedValuesDisciplines : disciplinesIds,
                learningPlansSkillCompetencesIds: skillCompetencesIds
            });    

        }else{

            for(var item in disciplines){

                //KnowledgeAreas
                var idKnow = disciplines[item].knowledgeArea;
                var indiceIdKnow = KnowledgeAreasIds.indexOf(idKnow);        
                KnowledgeAreasIds.splice(indiceIdKnow, 1);

                //Disciplines
                var idDisc = disciplines[item].id;
                var indiceIdDisc = disciplinesIds.indexOf(idDisc);        
                disciplinesIds.splice(indiceIdDisc, 1);

                //SkillCompetences
                var skillCompetences = disciplines[item].skillCompetences                
                for(var itemSkill in skillCompetences){
                    var idSkill = skillCompetences[itemSkill].id+'|'+idDisc;
                    var indiceIdSkill = skillCompetencesIds.indexOf(idSkill);        
                    skillCompetencesIds.splice(indiceIdSkill, 1);
                }
            }

            this.setState({
                selectedValuesDisciplines : disciplinesIds,
                learningPlansSkillCompetencesIds: skillCompetencesIds
            });
        }

        this.render();

    }

    selectAllSkillCompetencesExperienceFields (parans, target) {
        debugger
        let skillCompetences = parans.listSkillCompetences;
        let skillCompetencesIds = this.state.learningPlansSkillCompetencesIds;
        
        if(target.checked != false){ 

            for(var itemSkill in skillCompetences){
                var SkillCompetenceId = skillCompetences[itemSkill].id+'|'+parans.id;
                skillCompetencesIds.push(SkillCompetenceId);
            }

            this.setState({
                learningPlansSkillCompetencesIds : skillCompetencesIds,
            });     

        }else{

            for(var itemSkill in skillCompetences){
                var SkillCompetenceId = skillCompetences[itemSkill].id+'|'+parans.id;

                var indiceId = skillCompetencesIds.indexOf(SkillCompetenceId);        
                skillCompetencesIds.splice(indiceId, 1);
            }
            this.setState({
                learningPlansSkillCompetencesIds : skillCompetencesIds,
            });
        }

        this.render();

    }

    selectAllSkillCompetences (parans, target) {
        
        let knowledgeAreasIds = this.state.selectedValuesKnowledgeAreas;
        let disciplinesIds = this.state.selectedValuesDisciplines;
        let skillCompetences = parans.skillCompetences;
        let skillCompetencesIds = this.state.learningPlansSkillCompetencesIds;
        
        if(target.checked != false){ 

            for(var itemSkill in skillCompetences){
                var SkillCompetenceId = skillCompetences[itemSkill].id+'|'+parans.id;
                skillCompetencesIds.push(SkillCompetenceId);
            }

            knowledgeAreasIds.push(parans.knowledgeArea)
            disciplinesIds.push(parans.id) 

            this.setState({
                selectedValuesDisciplines: disciplinesIds,
                learningPlansSkillCompetencesIds : skillCompetencesIds,
            });     

        }else{

            for(var itemSkill in skillCompetences){
                var SkillCompetenceId = skillCompetences[itemSkill].id+'|'+parans.id;

                var indiceId = skillCompetencesIds.indexOf(SkillCompetenceId);        
                skillCompetencesIds.splice(indiceId, 1);
            }
         
            var indiceIdKnow = knowledgeAreasIds.indexOf(parans.knowledgeArea);        
            knowledgeAreasIds.splice(indiceIdKnow, 1);

            var indiceIdDisc = disciplinesIds.indexOf(parans.id);        
            disciplinesIds.splice(indiceIdDisc, 1);

            this.setState({
                selectedValuesDisciplines: disciplinesIds,
                learningPlansSkillCompetencesIds : skillCompetencesIds,
            });
        }

        this.render();

    }

    htmlSkillCompetences(){
        if(this.state.subLevel == 1){            
            return(
                <div>
                    <Label for="Status">Habilidades/Competências</Label>
                {this.state.listSkillCompetencesExperienceFields.length > 0 && this.state.listSkillCompetencesExperienceFields.map((item, index) => { 
                    return(
                        <ul className="hold-lists">
                            <li>
                                <input type="checkbox" checked={this.isSelectedExperienceFields(item)} value={item.id} onChange={({target}) => this.selectAllSkillCompetencesExperienceFields(item, target)}/>
                                <span className="txt-input">{item.name}</span>
                            </li>
                            {item.listSkillCompetences.map((itemSkill, index) => { 
                                return(
                                    <ul>
                                        <li>
                                            <input type="checkbox" {...this.isRequiredSkillCompetencesExperienceFields()} checked={this.isSelectedSkillCompetencesExperienceFields(itemSkill.id+'|'+item.id)} value={itemSkill.id} onChange={({ target }) =>this.onSelectSkillCompetencesExperienceFields(itemSkill.id+'|'+item.id, target)}/>
                                             {itemSkill.skillCompetencesCode} - {itemSkill.name}
                                        </li>
                                    </ul>
                                )    
                            })
                            }
                        </ul>
                    )
                })
                }
                </div>
            )
        }

        if(this.state.subLevel == 2){ 
            return(
                <div>
                    <Label for="Status">Habilidades/Competências</Label>
                {this.state.listSkillCompetences.length > 0 && this.state.listSkillCompetences.map((item, index) => { 
                    return(
                        <ul className="hold-lists">
                            <li>                            
                                <input type="checkbox" value={item.knowledgeAreaId}  onChange={({target}) => this.selectAllDisciplines(item, target)}/> 
                                <span className="txt-input">{item.knowledgeAreaName}</span>
                            </li>
                            {item.disciplines.length > 0 && item.disciplines.map((itemDisciplines, index) => {
                                return(
                                    <ul>
                                        <li>
                                            <input type="checkbox" checked={this.isSelectedDisciplines(itemDisciplines)} value={itemDisciplines.id} onChange={({target}) => this.selectAllSkillCompetences(itemDisciplines, target)}/>
                                            {itemDisciplines.name}
                                        </li>
                                        {itemDisciplines.skillCompetences.length > 0 && itemDisciplines.skillCompetences.map((itemSkillCompetences, index) => {
                                            return(
                                                <ul>
                                                    <li>
                                                        <input {...this.isRequiredSkillCompetences()} type="checkbox" checked={this.isSelectedSkillCompetences(itemSkillCompetences.id+'|'+itemDisciplines.id)} value={itemSkillCompetences.id+'|'+itemDisciplines.id} onChange={({ target }) =>this.onSelectSkillCompetences(item.knowledgeAreaId, itemSkillCompetences.id+'|'+itemDisciplines.id, target)}/>
                                                        {itemSkillCompetences.skillCompetencesCode} - {itemSkillCompetences.name}
                                                    </li>
                                                </ul> 
                                                
                                            )
                                        })
                                        }
                                    </ul> 
                                )
                            })
                            }
                        </ul>
                    )
                })}   
            </div>
            )
        }
    }

    render() {

        let showSuccess = this.state.successMsg.length > 0;
        let showError = this.state.errorMsg.length > 0;

        return (
            <main className="h-100 animated fadeIn">

                <AlertContext.Consumer>
                    {(value) => {
                        this.showSuccessMessage = value.success;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>

                <Container fluid className="h-100">
                    {showSuccess && <Alert color={'success'}>{this.state.successMsg}</Alert>}
                    {showError && <Alert color={'success'}>{this.state.errorMsg}</Alert>}
                    
                    <section className={`title-page mt-3`}>
                        <h2>{this.state.tipo}</h2>
                    </section>

                    <Col>                        
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Label for="name">Tema/Título</Label>
                                    <Input
                                        value={this.state.name}
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        placeholder="Etapa de ensino"
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>
                                <Col md={6}>
                                    <Label for="Status">Status</Label>
                                    <Input 
                                        type="select" 
                                        name="statusSelect" 
                                        id="statusSelect"
                                        value={this.state.active}
                                        required    
                                        onChange={({target}) => this.handleInputChange(target)}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value={"1"}>Ativo</option>
                                        <option value={"0"}>Inativo</option>
                                    </Input>
                                </Col>
                                <Col md={6}>
                                    <Label for="name">Duração</Label>
                                    <Input
                                        value={this.state.duration}
                                        id="duration"
                                        name="duration"
                                        type="text"
                                        required
                                        placeholder="3 horas/aula"
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>
                                <Col md={6}>
                                    <Label for="name">Palavras-chaves</Label>
                                    <Input
                                        value={this.state.searchKey}
                                        id="searchKey"
                                        name="searchKey"
                                        type="text"
                                        required
                                        placeholder="dia noite astros cotidiano Terra"
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>                                                                                    
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Label for="Status">Etapa de ensino</Label>
                                    <Input 
                                        type="select" 
                                        name="learningSteps" 
                                        id="learningSteps"
                                        value={this.state.learningStepId}                                            
                                        onChange={({target}) => this.handleInputChange(target)}
                                        required
                                        >
                                            <option value="">Selecione...</option>
                                            {                                            
                                                this.state.listLearningSteps.map((item) => {                                                
                                                    return(
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                    </Input>                                     
                                </Col>
                                <Col md={12}>
                                <div>
                                    
                                    {this.htmlSkillCompetences()}
                                </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Label for="Status">Objetivo geral</Label>
                                    <Input
                                        value={this.state.generalObjectives}
                                        id="generalObjectives"
                                        name="generalObjectives"
                                        type="textarea"
                                        required
                                        placeholder=""
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />    
                                </Col>
                                <Col md={6}>
                                    <Label for="Status">Objetivos específicos</Label>
                                    <Input
                                        value={this.state.specificObjectives}
                                        id="specificObjectives"
                                        name="specificObjectives"
                                        type="textarea"
                                        required
                                        placeholder=""
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>
                                <Col md={6}>
                                    <Label for="Status">Recursos didáticos</Label>
                                    <Input
                                        value={this.state.didacticResources}
                                        id="didacticResources"
                                        name="didacticResources"
                                        type="textarea"
                                        required
                                        placeholder=""
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>
                                <Col md={6}>
                                    <Label for="Status">Avaliação</Label>
                                    <Input
                                        value={this.state.assessment}
                                        id="assessment"
                                        name="assessment"
                                        type="textarea"
                                        required
                                        placeholder=""
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                    </Col>
                            </Row>                         
                            <Row>                                
                                <Col md={12}>
                                    <Label for="Status">Estratégias de aprendizagem</Label>
                                    <Editor                                        
                                        value={this.state.learningStrategies}
                                        init={{
                                          height: 500,
                                          menubar: true,
                                          plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                          ],
                                          toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onEditorChange={this.handleEditorChange}
                                    />
                                </Col>
                                <Col md={12}>
                                    <Label for="Status">Referências</Label>
                                    <Input
                                        value={this.state.references}
                                        id="references"
                                        name="references"
                                        type="textarea"
                                        required
                                        placeholder=""
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col> 
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Label for="Status">Roteiros relacionados</Label>   
                                    <div class="hold-lists min-70">
                                    { this.state.listLearningPlans.length > 0 && this.state.listLearningPlans.map((item, index) => { 
                                        return(
                                            <div>
                                                <input type="checkbox" checked={this.isSelectedLearninPlans(item.id)} value={item.id} onChange={({ target }) =>this.onSelectRelatedPlans(item, target)}/> 
                                                {item.name}
                                            </div>
                                        )
                                    })}   
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Label for="Status">Utilização das ferramentas digitais</Label>                
                                    <div className="hold-lists">
                                    { this.state.listDigitalTools.map((item, index) => { 
                                        return(
                                            <div>
                                                <input {...this.isRequiredDigitalTools()} type="checkbox" checked={this.isSelectedDigitalTools(item.id)} value={item.id} onChange={({ target }) =>this.onSelectDigitalTools(item, target)}/> 
                                                {item.name}
                                            </div>
                                        )
                                    })}   
                                    </div>
                                </Col>   
                            </Row>  
                            <Row>
                                <Col md={12}>
                                    <Upload ref={this.uploadChild} 
                                    uplaodUrl={`${Config.URLS.API}LearningPlansAttachments/UploadFiles/${this.state.id}`}/> 
                                </Col>
                            </Row>  
                            
                            <FormGroup>                                    
                                {
                                    this.state.learningPlansAttachments.map((item) => {                                                                                            
                                        return(
                                            <div value={item.id}>
                                                <a href={item.url}>{item.name}</a>                                              
                                                &nbsp;&nbsp;<span onClick={() => this.deleteAttachment(item.id)}><i id="deleteThumb" class="fas fa-times-circle" title="Excluir"></i></span>
                                            </div> 
                                                                                        
                                        )
                                    })
                                }
                            </FormGroup>  

                            <Row className="hold-buttons">
                                <Col className="mb-5">
                                    <Button id={"btnSave"}
                                        color="primary" type="submit">
                                        <span>Salvar</span>
                                    </Button>{' '}
                                    <Link to={`/roteiros-de-aprendizagem`}> <Button color="primary">Cancelar</Button> </Link>
                                </Col>
                            </Row>                            
                        </Form>
                    </Col>
                </Container>
            </main>
        );
    }
}

export default withRouter(LearningPlansForm);