import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Footer from '../Layouts/Footer';

class Home extends Component {
    render() {
        return (
            <div className="bgAll animated fadeIn">                
                {/* <div className="col-md-12">
                    <img className="logo" src="img/logo-plano-aula.png" alt="Logo" />
                </div>                 */}
                <Footer/>
            </div>
        )
    }
}

export default Home;