import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Alert, Card, Container, Row, Button, Col, Form ,FormGroup, Input, Label} from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { AlertContext } from "../../providers/AlertProvider";

import ReactTable from 'react-table'
import Service from '../../services/DigitalTools/DigitalToolsServices';

import "react-table/react-table.css";
import 'react-confirm-alert/src/react-confirm-alert.css';

class DigitalTools extends Component {
    constructor(props) {
        super(props);
        this.state = {
            successMsg: '',
            errorMsg: '',
            warningMsg: '',
            listDigitalTools: [],
            filter:{
                active:'',
                name:''
            },
            pageAtual: 0,
            page: 0,
            pageSize: 5,
			btProx: false,
            btAnte: true,
            loading: false,
            invalid: false
        }
    }

    resetFilter = () => {

        let filter = {
            active:'',
            name:''
        }
        
        this.setState({filter})
        this.loadData({ page: 0, pageSize: 5 });
    }

    filter = () =>{ 
        this.setState({ loading: true });

        let dataFilter = {}

        if(this.state.filter.active !== ''){
            dataFilter.active = this.state.filter.active
        }

        dataFilter.name = this.state.filter.name
        dataFilter.page = this.state.pages
        dataFilter.pageSize = this.state.pageSize


        Service.search(dataFilter, (data) => {
            this.setState({
                listDigitalTools: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    submit = (id, name) => {
        confirmAlert({
            title: name,
            message: 'Deseja realizar a exclusão deste item?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => this.deleteCategory(id)
                },
                {
                    label: 'Não',
                    onClick: () => console.log(id)
                }
            ]
        });
    };

    handleInputChange = (target) =>{
        const value = target.value;
        let filter = this.state.filter;

        if (target.id === 'statusSelect') {
            this.setState({
                filter : {active : value}
            })
        }
        if (target.id === 'name') {
            filter.name = value
            this.setState({
                filter
            })
        }
    }

    loadData = (state) => {  
        Service.getData(this.state.page, this.state.pageSize, (data) => {
            this.setState({
                listDigitalTools: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    loadPageData = (target) => {  
        const value = target.type === 'checkbox' ? target.checked : target.value; 
        Service.getData(this.state.pageAtual, value, (data) => {
            this.setState({
                listDigitalTools: data,
                pageSize: value,
                loading: false
            });
        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };
    
    loadAnteriorData = () => {          
        var page = this.state.pageAtual -  1;
        debugger
        if(page >= 0){
            Service.getData(page, this.state.pageSize, (data) => {
                this.setState({
                    listDigitalTools: data,
                    pageAtual: page,
                    loading: false
                });
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            });
        }
    };

    loadProximoData = () => {          
        var page = this.state.pageAtual +  1;                
        Service.getData(page, this.state.pageSize, (data) => {
            this.setState({
                listDigitalTools: data,
                pageAtual: page,
                btAnte: false,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    deleteCategory = (id) => {
        this.setState({ loading: true });
        Service.removeData(id, (data) => {
            this.loadData();
            this.showWarningMessage("Item excluido com sucesso  .....");
        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    htmlPagination = () => {
        return(
            <Row style={{marginLeft: '3%', marginTop: '10px'}} >
                <Col md={4} lg={4} sm={4} xs={4} >
                    <button style={{width: '100%', marginLeft: '-10%'}} className="btn-primary" onClick={this.loadAnteriorData} >Anterior</button>{' '}
                    </Col>
                    <Col md={2} lg={2} sm={2} xs={2}>
                        <div style={{textAlign: 'center', margin: '1%', marginLeft: '-60px', marginTop: '7px'}}>
                            Página {this.state.pageAtual + 1} 
                        </div>
                    </Col>
                    <Col md={1} lg={1} sm={1} xs={1}>
                        <Input type="select" name="pageSizeOptions" id="pageSizeOptions" style={{width: '150px', marginLeft: '-55px', cursor: 'pointer'}}
                                        value={this.state.pageSize}
                                        onChange={({target}) => this.loadPageData(target)}>
                                    <option value="5">5 Registros</option>
                                    <option value="10">10 Registros</option>
                                    <option value="20">20 Registros</option>
                                    <option value="25">25 Registros</option>
                                    <option value="50">50 Registros</option>
                                    <option value="100">100 Registros</option>
                                </Input>
                        </Col>
                    <Col md={4} lg={4} sm={4} xs={4}>
                    <button style={{width: '100%', marginLeft: '22%'}} className="btn-primary" onClick={this.loadProximoData}>Próxima</button>
                </Col>
            </Row>
        )
    }

    render() {
        let showSuccess = this.state.successMsg.length > 0;
        let showError = this.state.errorMsg.length > 0;
        let showWarning = this.state.warningMsg.length > 0;
        
        let rawDate = this.state.listDigitalTools;
        let rawData = rawDate.sort(function(a,b) {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });

        const columns = [
            {
                Header: 'Ferramenta digital',
                accessor: 'name',
                maxWidth: 500,
            },
            {
                Header: 'Status',
                accessor: 'active',
                maxWidth: 500,
                Cell: (cellProps) => {

                    if(cellProps.original.active=== true ) return("Ativo")

                    return ("Inativo");
                }
            }, 
            {
                Header: 'Ações',
                accessor: 'id',
                Cell: (cellProps) => {

                    let id = cellProps.original.id;
                    let name = cellProps.original.name;
                    return <div>
                        <Link to={`/ferramentas-digitais/${id}`}><button className="btn"><i className="fas fa-edit"></i>
                        </button></Link>

                        {'    '}
                        <button className="btn"
                            onClick={() => this.submit(id, name)}>
                            <i className="fas fa-trash"></i>
                        </button>

                    </div>
                }
            }];

        return (
            <main className="h-100 animated fadeIn">
                <AlertContext.Consumer>
                    {(value) => {
                        this.showInfoMessage = value.info;
                        this.showSuccessMessage = value.success;
                        this.showWarningMessage = value.warning;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>

                <Container fluid className="h-100">
                    {showSuccess && <Alert color={'success'}>{this.state.successMsg}</Alert>}
                    {showError && <Alert color={'success'}>{this.state.errorMsg}</Alert>}
                    {showWarning && <Alert color={'success'}>{this.state.warningMsg}</Alert>}

                    <Row className="hold-title">
                        <Col md={11} className={`title-page`}>
                            <h2>Ferramentas digitais</h2>
                        </Col>
                        <Col md={1} className="hold-plus">
                            <Link to={`/ferramentas-digitais/add`} className="bbtn">
                                <Button
                                    color="plus"
                                    block
                                    alt="Adicionar"
                                    title="Adicionar"
                                >
                                    <i className="fas fa-plus" />
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                    <Container>
                        <Row>
                            <Col md={4}>
                                <Label for="name">Ferramenta digital</Label>                                        
                                <Input
                                    value={this.state.filter.name}
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder="Ferramenta digital"
                                    maxLength={300}
                                    onChange={({ target }) => this.handleInputChange(target)} />
                            </Col>
                            <Col md={4}>
                                <Label for="Status">Status</Label>
                                <Input 
                                    type="select" 
                                    name="active" 
                                    id="statusSelect"
                                    value={this.state.filter.active}
                                    onChange={({target}) => this.handleInputChange(target)}
                                >
                                    <option value="">Todos</option>
                                    <option value={true}>Ativo</option>
                                    <option value={false}>Inativo</option>
                                </Input> 
                            </Col>
                        </Row>
                        <Row className="hold-buttons">
                            <Col>
                                <Button
                                    id={"btnSearch"}
                                    color="primary"
                                    onClick={this.filter}
                                >
                                    <span>Pesquisar</span>
                                </Button>
                                <Button
                                    id={"btnReset"}
                                    color="primary"
                                    onClick={this.resetFilter}
                                >
                                    <span>Limpar</span>
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                        <section className="pdd-table">
                            <ReactTable
                                className="h-200"
                                PaginationComponent={this.htmlPagination}
                                data={rawData}
                                columns={columns}
                                loading={this.state.loading}
                                sortable={true} 
                                noDataText={"Nenhum registro encontrado"}
                                minRows={2}
                                onFetchData={this.loadData}                                                                        
                            />
                        </section>
                </Container>
            </main >
        );
    }
}

export default withRouter(DigitalTools);