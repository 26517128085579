import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Multiselect } from 'multiselect-react-dropdown';


import Service from '../../services/SkillCompetences/SkillCompetencesServices';
import ServiceLearningSteps from '../../services/LearningSteps/LearningStepsServices';
import ServiceDisciplines from '../../services/Disciplines/DisciplinesServices';
import ServiceExperienceFields from '../../services/ExperienceFields/ExperienceFieldsServices';

import { AlertContext } from '../../providers/AlertProvider'
import AADService from '../../services/AADService'
import { defaultGroupByFn } from 'react-table';

class SkillCompetencesForm extends Component {
    constructor(props) {
        super(props);
        
        this.AzureADService = new AADService();
        this.UserInfo = this.AzureADService.getUser();

        this.state = {
            successMsg: '',
            errorMsg: '',
            tipo: '',
            styleActive: "none",
            active: '',
            page: 0,
            pageSize: 100,
            learningStepId: '',
            subLevel : 0,            
            listLearningSteps: [],
            listDisciplines: [],
            listExperienceFields: [],
            skillCompetencesDisciplinesIds: [],
            skillCompetencesExperienceFieldIds: [],          
            model: {
                skillCompetencesCode: '',
                name: '',
                id: '',
                createdBy: '',
                modifiedBy: ''
            }
        }
    }

    getLearningSteps = (state) => {
        
        this.setState({ loading: true });
        ServiceLearningSteps.getData(state.page, state.pageSize, (data) => {
            this.setState({
                listLearningSteps: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    getDisciplinesByLearningStepId = (learningStepId,state) => {
        
        this.setState({ loading: true });
        ServiceDisciplines.GetDisciplinesByLearningStepId(learningStepId, (data) => {            
            this.setState({
                listDisciplines: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    getExperienceFieldsByLearningStepId = (id, state) => {
        
        this.setState({ loading: true });
        ServiceExperienceFields.getExperienceFieldsByLearningStepId(id, (data) => {
            this.setState({
                listExperienceFields: data,
                pages: data.pages,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });
    };

    resetValues = () => {
        this.multiselectRef.current.resetSelectedValues();
    }

    handleClick = () => {
        this.props.history.push("/habilidades-e-competencias");
    }

    onSelectDisciplines = (parans, target) => {
           
        let disciplineId = parans.id
        let disciplines = this.state.skillCompetencesDisciplinesIds;

        if(target.checked != false){                    
            disciplines.push(disciplineId);
            this.setState({
                skillCompetencesDisciplinesIds : disciplines
            });        
        }
        else{            
            var indice = disciplines.indexOf(disciplineId);        
            disciplines.splice(indice, 1);
            this.setState({
                skillCompetencesDisciplinesIds : disciplines
            });
        }        
    }

    onSelectExperienceFields = (parans, target) => {
        
        let experiencieFieldId = parans.id
        let experiencieFields = this.state.skillCompetencesExperienceFieldIds;   

        if(target.checked != false){      
            experiencieFields.push(experiencieFieldId);
            this.setState({
                skillCompetencesExperienceFieldIds : experiencieFields
            });   
        }else{
            var indice = experiencieFields.indexOf(experiencieFieldId);        
            experiencieFields.splice(indice, 1);
            this.setState({
                skillCompetencesExperienceFieldIds : experiencieFields
            });
        }             
    }

    
    selectAllDisciplines (parans, target) {
        
        let disciplinesknowledgeArea = parans.disciplines;
        let disciplinesIds = this.state.skillCompetencesDisciplinesIds;
        
        if(target.checked != false){ 

            for(var item in disciplinesknowledgeArea){
                disciplinesIds.push(disciplinesknowledgeArea[item].id);
            }

            this.setState({
                skillCompetencesDisciplinesIds : disciplinesIds
            });                   
        }else{

            for(var item in disciplinesknowledgeArea){
                
                var indiceId = disciplinesIds.indexOf(disciplinesknowledgeArea[item].id);        
                disciplinesIds.splice(indiceId, 1);

            }

            this.setState({
                skillCompetencesDisciplinesIds : disciplinesIds
            });
        }

        this.render();

    }
        

    handleInputChange(target) {

        const value = target.value;
        const field = target.name;
        this.setState({
            model: { ...this.state.model, [field]: value },
            invalid: { ...this.state.invalid, [field]: target.required && !value },
        });

        if (target.id === 'name') {
            this.setState({
                name: value
            })
        }

        if (target.id === 'statusSelect') {
            this.setState({
                active: value
            })
        }

        if (target.id === 'LearningSteps') { 
            
            let subLevel = 0;
            if(target.value != ""){
                if(this.state.listLearningSteps.length > 0){
                    subLevel = this.state.listLearningSteps.find(x => x.id == target.value).subLevel;
                }
           
                this.setState({
                    learningStepId: value,
                    subLevel: subLevel
                })
                
                if(subLevel == 1)
                {
                    this.getExperienceFieldsByLearningStepId(target.value, this.state);                    
                }
                if(subLevel == 2)
                {
                    this.getDisciplinesByLearningStepId(target.value, this.state);
                }
              
                this.setState({
                    skillCompetencesDisciplinesIds: [],
                    skillCompetencesExperienceFieldIds: []
                });  
             
            }else{
                this.setState({
                    subLevel: subLevel,
                    listExperienceFields: [],
                    listDisciplines: []
                }); 
            } 
        }
    }

    componentDidMount() {
        
        const { match: { params } } = this.props;

        if (params.id !== undefined) {
            Service.getDetails(params.id, (data) => {                
                let ativo = data.active === true ? "1" : "0";
                debugger
                this.setState({
                    id: params.id,
                    styleActive: "flex",
                    tipo: 'Editar Habilidade e compentência',
                    active: ativo,
                    learningStepId: data.learningStepId,
                    model: data,                
                    skillCompetencesDisciplinesIds: data.skillCompetencesDisciplinesIds,
                    skillCompetencesExperienceFieldIds: data.skillCompetencesExperienceFieldIds
                });

                if(data.learningStep != null){
                    this.setState({
                        subLevel : data.learningStep.subLevel         
                    });
                    
                    if(this.state.subLevel == 2){                                        
                        this.getDisciplinesByLearningStepId(data.learningStepId, this.state); 
                    }else{
                        this.getExperienceFieldsByLearningStepId(data.learningStepId, this.state); 
                    }
                }
           
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            })
        } else {
            let newModel = this.state.model;
            newModel.name = '';
            newModel.modifiedBy = '';
            newModel.createdBy = '';

            this.setState({
                tipo: 'Adicionar Habilidade e compentência',
                model: newModel
            })
        }
       
        this.getLearningSteps(this.state);
    };

    handleSubmit = e => { 

        e.preventDefault();
        let isUpdate = this.state.id !== undefined;
                 
        try {
            if (isUpdate) {
                
                const { match: { params } } = this.props;
                
                let dado = { 
                    "id": params.id, 
                    "skillCompetencesCode": this.state.model.skillCompetencesCode, 
                    "name": this.state.model.name, 
                    "createdBy": this.UserInfo.profile.name, 
                    "createdByEmail": this.UserInfo.profile.unique_name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name,
                    "active": (this.state.active === "1" ? true : false),
                    "SkillCompetencesCode": this.state.code,
                    "LearningStepId" : this.state.learningStepId,  
                    "SkillCompetencesDisciplinesIds" : this.state.skillCompetencesDisciplinesIds,    
                    "SkillCompetencesExperienceFieldIds" : this.state.skillCompetencesExperienceFieldIds                      
                }

                console.log(dado)
                
                Service.updateData(params.id, dado, (data) => {
                    let newModel = this.state.model;
                    newModel.name = this.state.model.name;
                    newModel.modifiedBy = this.state.model.name;
                    newModel.id = params.id;
                    this.showSuccessMessage("Habilidade/Competência atualizado com sucesso!");
                    this.handleClick();
                    this.setState({
                        model: newModel
                    });
                    
                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                })
            } else {
                
                let novodado = { 
                    "skillCompetencesCode": this.state.model.skillCompetencesCode, 
                    "name": this.state.model.name, 
                    "createdBy": this.UserInfo.profile.name, 
                    "createdByEmail": this.UserInfo.profile.unique_name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name,
                    "active": (this.state.active === "1" ? true : false),
                    "SkillCompetencesCode": this.state.code,
                    "LearningStepId" : this.state.learningStepId,  
                    "SkillCompetencesDisciplinesIds" : this.state.skillCompetencesDisciplinesIds,    
                    "SkillCompetencesExperienceFieldIds" : this.state.skillCompetencesExperienceFieldIds     
                    
                }

                Service.saveData(novodado, (data) => {
                    console.log(this.UserInfo.profile.unique_name)
                    this.showSuccessMessage("Habilidade/Competência salva com sucesso!");
                    let newModel = this.state.model;
                    newModel.name = this.state.model.name;
                    newModel.modifiedBy = this.state.model.name;
                    newModel.createdBy = this.state.model.createdBy;

                    this.handleClick();
                    this.setState({
                        model: newModel
                    });

                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                });
            }
        } catch (e) {
            this.showErrorMessage(e.message || e.response.data.Error);
        }
    };

    updateInputValue(evt) {
        this.setState({
            model: evt.target.value
        })
    };


    isSelectedknowledgeArea = (knowledgeArea) => {

        let disciplines = knowledgeArea.disciplines;
        let disciplinesCount = knowledgeArea.disciplines.length;
        let disciplinesSelectedCount = 0;

        for(var item in disciplines){
            var existe = this.state.skillCompetencesDisciplinesIds.includes(disciplines[item].id)
            if(existe)
                disciplinesSelectedCount++;
        }

        if(disciplinesCount === disciplinesSelectedCount){
            return 'true'
        }
        return ''
    }


    isSelectedDiscipline = (id) => {
        
        let selecteds = this.state.skillCompetencesDisciplinesIds.filter((item) => {
            return (item === id)
        })

        if(selecteds.length >0){
            return 'true'
        }
        return ''
    }

    isSelectedExperienceField = (id) => {
        
        let selecteds = this.state.skillCompetencesExperienceFieldIds.filter((item) => {
            return item === id
        })

        if(selecteds.length >0){
            return 'true'
        }
        return ''
    }

    isRequired = () => {
        
        let selectedsDis = this.state.skillCompetencesDisciplinesIds
        let selectedsExp= this.state.skillCompetencesExperienceFieldIds

        if(selectedsDis.length == 0 && selectedsExp.length == 0 ){
            return {
                 required: "required"
                };
        }
        return ''
    }

    htmlExperienceFieldsDisciplines(){

        if(this.state.subLevel == 1){      
            
            return(
                <FormGroup>
                    <Row>
                        <Col md={12}>
                            <Label for="ExperienceFields">Campos de experiência relacionado</Label>   
                            <div className="hold-lists">
                                { this.state.listExperienceFields.map((item, index) => { 
                                    return(
                                        <div>
                                            <input {...this.isRequired()} type="checkbox" checked={this.isSelectedExperienceField(item.id)} value={item.id} onChange={({target}) => this.onSelectExperienceFields(item, target)}/> 
                                            {item.name}
                                        </div>
                                    )
                                })}   
                            </div>
                        </Col>
                    </Row>
                </FormGroup>             
            )
        }

        if(this.state.subLevel == 2){           
            return(
                <Col md={12}>
                    <Label for="Disciplines">Áreas do conhecimento/Disciplinas</Label>  
                    <div className="hold-lists">                          
                        {                                            
                        this.state.listDisciplines.map((knowledgeArea) => {                                                
                            return(
                                <ul>
                                    <li>
                                        <input type="checkbox" value={knowledgeArea.id} checked={this.isSelectedknowledgeArea(knowledgeArea)} onChange={({target}) => this.selectAllDisciplines(knowledgeArea, target)}/> 
                                        <span className="txt-input">{knowledgeArea.knowledgeAreaName}</span>
                                    </li>

                                    { knowledgeArea.disciplines.map((item, index) => { 
                                        return(
                                            <ul>
                                                <li>
                                                    <input type="checkbox" {...this.isRequired()} checked={this.isSelectedDiscipline(item.id)} value={item.id} onChange={({target}) => this.onSelectDisciplines(item, target)}/> 
                                                    {item.name}
                                                </li>
                                            </ul> 
                                        )
                                    })}   
                                </ul>
                            )
                        })
                        }                    
                    </div>
                </Col>
            )
        }
    }

    render() {

        let showSuccess = this.state.successMsg.length > 0;
        let showError = this.state.errorMsg.length > 0;
        
        return (
            <main className="h-100 animated fadeIn">

                <AlertContext.Consumer>
                    {(value) => {
                        this.showSuccessMessage = value.success;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>

                <Container fluid className="h-100">
                    {showSuccess && <Alert color={'success'}>{this.state.successMsg}</Alert>}
                    {showError && <Alert color={'success'}>{this.state.errorMsg}</Alert>}

                    <section className={`title-page mt-3`}>
                        <h2>{this.state.tipo}</h2>
                    </section>
                    <Col>
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Label for="name">Código Habilidade/Competência</Label>
                                    <Input
                                        value={this.state.model.skillCompetencesCode}
                                        id="skillCompetencesCode"
                                        name="skillCompetencesCode"
                                        type="text"
                                        required
                                        placeholder="Código Habilidade/Competência"
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>
                                <Col md={6}>
                                    <Label for="name">Habilidade/Competência</Label>
                                    <Input
                                        value={this.state.model.name}
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        placeholder="Habilidade/Competência"
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Label for="Status">Status</Label>
                                    <Input 
                                        type="select" 
                                        name="active" 
                                        id="statusSelect"
                                        value={this.state.active}
                                        required    
                                        onChange={({target}) => this.handleInputChange(target)}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value={"1"}>Ativo</option>
                                        <option value={"0"}>Inativo</option>
                                    </Input> 
                                </Col>                                
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Label for="LearningSteps">Etapas de Ensino</Label>                                    
                                        <Input 
                                            type="select" 
                                            name="LearningSteps" 
                                            id="LearningSteps"
                                            value={this.state.learningStepId}    
                                            onChange={({target}) => this.handleInputChange(target)}
                                            required
                                        >
                                            <option value="">Selecione...</option>
                                            {                                            
                                                this.state.listLearningSteps.map((item) => {                                                
                                                    return(
                                                        <option value={item.id}>{item.name}</option>
                                                    )
                                                })
                                            }
                                        </Input> 
                                        <div>
                                            {this.htmlExperienceFieldsDisciplines()}
                                        </div>
                                    </Col>
                                </Row>
                            <section className="text-right">
                                <Button id={"btnSave"}
                                        color="primary" type="submit">
                                        <span>Salvar</span>
                                </Button>{' '}
                                <Link to={`/habilidades-e-competencias`}> <Button color="primary">Cancelar</Button> </Link>
                            </section>                            
                        </Form>
                    </Col>
                </Container>
            </main>
        );
    }
}

export default withRouter(SkillCompetencesForm);