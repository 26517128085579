import React, { Component } from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import Home from './components/Home/Home';
import LayoutDefault from './components/Layouts/LayoutDefault';

import DigitalTools from './components/DigitalTools/DigitalTools';
import DigitalToolsForm from './components/DigitalTools/DigitalToolsForm';

import LearningSteps from './components/LearningSteps/LearningSteps';
import LearningStepsForm from './components/LearningSteps/LearningStepsForm';

import Disciplines from './components/Disciplines/Disciplines';
import DisciplinesForm from './components/Disciplines/DisciplinesForm';

import ExperienceFields from './components/ExperienceFields/ExperienceFields';
import ExperienceFieldsForm from './components/ExperienceFields/ExperienceFieldsForm';

import SkillCompetences from './components/SkillCompetences/SkillCompetences';
import SkillCompetencesForm from './components/SkillCompetences/SkillCompetencesForm';

import LearningPlans from './components/LearningPlans/LearningPlans'
import LearningPlansForm from './components/LearningPlans/LearningPlansForm'

import FeedBack from './components/Feedbacks/Feedbacks'
import FeedBackDetails from './components/Feedbacks/FeedbacksDetails'

class Routes extends Component {
    render(){ 
        return(
            <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <LayoutDefault>
                        <Home />
                    </LayoutDefault>
                </Route>
                <Route exact path="/campos-de-experiencia">
                    <LayoutDefault>
                        <ExperienceFields />
                    </LayoutDefault>
                </Route>
                <Route exact path="/campos-de-experiencia/add">
                    <LayoutDefault>
                        <ExperienceFieldsForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/campos-de-experiencia/:id">
                    <LayoutDefault>
                        <ExperienceFieldsForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/etapas-de-ensino">
                    <LayoutDefault>
                        <LearningSteps />
                    </LayoutDefault>
                </Route>
                <Route exact path="/etapas-de-ensino/add">
                    <LayoutDefault>
                        <LearningStepsForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/etapas-de-ensino/:id">
                    <LayoutDefault>
                        <LearningStepsForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/feedbacks">
                    <LayoutDefault>
                        <FeedBack />
                    </LayoutDefault>
                </Route>
                <Route exact path="/feedbacks-details/:id">
                    <LayoutDefault>
                        <FeedBackDetails />
                    </LayoutDefault>
                </Route>
                <Route exact path="/ferramentas-digitais">
                    <LayoutDefault>
                        <DigitalTools />
                    </LayoutDefault>
                </Route>
                <Route exact path="/ferramentas-digitais/add">
                    <LayoutDefault>
                        <DigitalToolsForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/ferramentas-digitais/:id">
                    <LayoutDefault>
                        <DigitalToolsForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/habilidades-e-competencias">
                    <LayoutDefault>
                        <SkillCompetences />
                    </LayoutDefault>
                </Route>
                <Route exact path="/habilidades-e-competencias/add">
                    <LayoutDefault>
                        <SkillCompetencesForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/habilidades-e-competencias/:id">
                    <LayoutDefault>
                        <SkillCompetencesForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/disciplina">
                    <LayoutDefault>
                        <Disciplines/>
                    </LayoutDefault>
                </Route>
                <Route exact path="/disciplina/add">
                    <LayoutDefault>
                        <DisciplinesForm />
                    </LayoutDefault>
                </Route>
                <Route exact path="/disciplina/:id">
                    <LayoutDefault>
                        <DisciplinesForm/>
                    </LayoutDefault>
                </Route>
                <Route exact path="/roteiros-de-aprendizagem">
                    <LayoutDefault>
                        <LearningPlans/>
                    </LayoutDefault>
                </Route>
                <Route exact path="/roteiros-de-aprendizagem/add">
                    <LayoutDefault>
                        <LearningPlansForm/>
                    </LayoutDefault>
                </Route>
                <Route exact path="/roteiros-de-aprendizagem/:id">
                    <LayoutDefault>
                        <LearningPlansForm/>
                    </LayoutDefault>
                </Route>
            </Switch>
        </BrowserRouter>

        );

    }
}

export default Routes;


