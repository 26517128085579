import { Component } from 'react';

class Menu extends Component {
	constructor(props) {
		super(props);
		this.itens = [		
			{
				nome: 'Home',
				title: 'Home',
				path: '/',
				ico: 'fas fa-home',
			},
			{
				nome: 'Roteiros de aprendizagem',
				title: 'Roteiros de aprendizagem',
				path: '/roteiros-de-aprendizagem',
				ico: 'fab fa-leanpub',
			},
			{
				nome: 'Etapas de ensino',
				title: 'Etapas de ensino',
				path: '/etapas-de-ensino',
				ico: 'fab fa-leanpub',
			},
			{
				nome: 'Disciplinas',
				title: 'Disciplinas',
				path: '/disciplina',
				ico: 'fas fa-cubes',
			},
			{
				nome: 'Campos de experiência',
				title: 'Campos de experiência',
				path: '/campos-de-experiencia',
				ico: 'fab fa-leanpub',
			},
			{
				nome: 'Habilidades e competências',
				title: 'Habilidades e competências',
				path: '/habilidades-e-competencias',
				ico: 'fas fa-cubes',
			},
			{
				nome: 'Ferramentas Digitais',
				title: 'Ferramentas Digitais',
				path: '/ferramentas-digitais',
				ico: 'fas fa-cubes',
			},
			{
				nome: 'Feedbacks',
				title: 'Feedbacks',
				path: '/feedbacks',
				ico: 'fas fa-cubes',
			},		
			
		];
	}

	get Items() {
		return this.itens;
	}
}

export default Menu;
