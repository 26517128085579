import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';

import Service from '../../services/Disciplines/DisciplinesServices';
import ServiceLearningSteps from '../../services/LearningSteps/LearningStepsServices';

import { AlertContext } from '../../providers/AlertProvider'
import AADService from '../../services/AADService'

class DisciplinesForm extends Component {
    constructor(props) {
        super(props);

        this.AzureADService = new AADService();
        this.UserInfo = this.AzureADService.getUser();

        this.state = {
            successMsg: '',
            errorMsg: '',
            tipo: '',
            styleActive: "none",
            active: '',
            page: 0,
            pageSize: 100,
            listOfLearningSteps:[],
            selectedLearningSteps:[],
            classOption:'',
            knowledgeArea:'',
            listknowledgeArea:[],
            model: {
                name: '',
                id: '',
                createdBy: '',
                modifiedBy: ''
            }
        }
    }

    handleClick = () => {
        this.props.history.push("/disciplina");
    }

    handleInputChange(target) {

        const value = target.value;
        const field = target.name;
        this.setState({
            model: { ...this.state.model, [field]: value },
            invalid: { ...this.state.invalid, [field]: target.required && !value },
        });
        if (target.id === 'statusSelect') {
            this.setState({
                active: value
            })
        }
        if (target.id === 'knowledgeAreaSelect') {
            this.setState({
                knowledgeArea: parseInt(value)
            })
        }
    }

    getknowledgeArea = () => {
        
        var arrayknowledgeArea = [
            {"id": 1, "name" : "Linguagens e suas tecnologias"},
            {"id": 2, "name" : "Matemática e suas tecnologias"},
            {"id": 3, "name" : "Ciências da natureza e suas tecnologias"},
            {"id": 4, "name" : "Ciências humanas e sociais aplicadas"},
            {"id": 5, "name" : "Formação técnica e profissional"},
            {"id": 6, "name" : "Linguagens"},
            {"id": 7, "name" : "Matemática"},
            {"id": 8, "name" : "Ciências da natureza"},
            {"id": 9, "name" : "Ciências humanas"},
            {"id": 10, "name" : "Ensino religioso"},
        ];

        arrayknowledgeArea = arrayknowledgeArea.sort((a, b) => a.name.localeCompare(b.name))

        this.setState({
            listknowledgeArea: arrayknowledgeArea
        });
    };

    componentDidMount = () => {
        
        const { match: { params } } = this.props;
        let context = this;

        let dataFilter = {
            active:true
        }

        this.getknowledgeArea();

        this.setState({ loading: true });
        ServiceLearningSteps.getLearningStepsForDisciplines((data) => {
            this.setState({
                listOfLearningSteps: data,
                loading: false
            });

        }, (err) => {
            this.showErrorMessage(err.message || err.response.data.Error);
        });

        if (params.id !== undefined) {
            Service.getDetails(params.id, (data) => {
                let ativo = data.active === true ? "1" : "0";

                let selectedLearningSteps = []

                data.disciplineLearningSteps.forEach(item =>{
                    selectedLearningSteps = selectedLearningSteps.concat(item.learningStepsId)
                })

                context.setState({
                    id: params.id,
                    styleActive: "flex",
                    tipo: 'Editar Disciplinas',
                    active: ativo,
                    knowledgeArea: data.knowledgeArea,
                    selectedLearningSteps:selectedLearningSteps,
                    model: data
                });
            }, (err) => {
                this.showErrorMessage(err.message || err.response.data.Error);
            })
        } else {
            let newModel = this.state.model;
            newModel.name = '';
            newModel.modifiedBy = '';
            newModel.createdBy = '';

            this.setState({
                tipo: 'Adicionar Disciplinas',
                model: newModel
            })
        }
    };

    handleLearnStepSelect = (e,target) => {

        console.log(e)
        console.log(target)

        target.selected = true
        let selectedLearningSteps = this.state.selectedLearningSteps

        let add = true

        selectedLearningSteps.forEach(element => {
            if(element === target.value){
                add = false
            }        
        });

        if (add) {
            selectedLearningSteps = selectedLearningSteps.concat(target.value) 
        } else {

            selectedLearningSteps = selectedLearningSteps.filter((item) => {
                return item !== target.value
            })
        }

        this.setState({
            selectedLearningSteps
        })
    }


    isSelected = (id) =>{
        
        let selected = this.state.selectedLearningSteps.filter((item) => {
            return item === id
        })

        if(selected.length >0){
            return'true'
        }
        return ''
    }

    isRequired = () => {
        
        let selectedsLearningSteps = this.state.selectedLearningSteps

        if(selectedsLearningSteps.length == 0 ){
            return {
                 required: "required"
                };
        }
        return ''
    }

    handleSubmit = e => { 

        e.preventDefault();
        let isUpdate = this.state.id !== undefined;
                 
        try {
            if (isUpdate) {
                
                const { match: { params } } = this.props;

                let disciplineLearningSteps = []
                
                this.state.selectedLearningSteps.forEach(item => {
                    disciplineLearningSteps = disciplineLearningSteps.concat({
                        LearningStepsId:item
                    })
                })
                
                let dado = { 
                    "id": params.id, 
                    "name": this.state.model.name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name,
                    "active": (this.state.active === "1" ? true : false),
                    "knowledgeArea": this.state.knowledgeArea,
                    "disciplineLearningSteps":disciplineLearningSteps
                }

                console.log(dado)
                
                Service.updateData(params.id, dado, (data) => {
                    let newModel = this.state.model;
                    newModel.name = this.state.model.name;
                    newModel.modifiedBy = this.state.model.name;
                    newModel.id = params.id;
                    this.showSuccessMessage("Etapa de ensino salva com sucesso!");
                    this.handleClick();
                    this.setState({
                        model: newModel
                    });
                    
                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                })
            } else {

                let disciplineLearningSteps = []
                
                this.state.selectedLearningSteps.forEach(item => {
                    disciplineLearningSteps = disciplineLearningSteps.concat({
                        LearningStepsId:item
                    })
                })
                
                let novodado = { 
                    "name": this.state.model.name, 
                    "createdBy": this.UserInfo.profile.name, 
                    "createdByEmail": this.UserInfo.profile.unique_name, 
                    "modifiedBy": this.UserInfo.profile.name, 
                    "modifiedEmail": this.UserInfo.profile.unique_name,
                    "active": (this.state.active === "1" ? true : false),
                    "knowledgeArea": this.state.knowledgeArea,
                    "disciplineLearningSteps":disciplineLearningSteps
                }

                Service.saveData(novodado, (data) => {
                    this.showSuccessMessage("Etapa de ensino salva com sucesso!");
                    let newModel = this.state.model;
                    newModel.name = this.state.model.name;
                    newModel.modifiedBy = this.state.model.name;
                    newModel.createdBy = this.state.model.createdBy;

                    this.handleClick();
                    this.setState({
                        model: newModel
                    });

                }, (err) => {
                    this.showErrorMessage(err.message || err.response.data.Error);
                });
            }
        } catch (e) {
            this.showErrorMessage(e.message || e.response.data.Error);
        }
    };

    updateInputValue(evt) {
        this.setState({
            model: evt.target.value
        })
    };


    render() {

        let showSuccess = this.state.successMsg.length > 0;
        let showError = this.state.errorMsg.length > 0;

        return (
            <main className="h-100 animated fadeIn">

                <AlertContext.Consumer>
                    {(value) => {
                        this.showSuccessMessage = value.success;
                        this.showErrorMessage = value.error;
                    }}
                </AlertContext.Consumer>

                <Container fluid className="h-100">
                    {showSuccess && <Alert color={'success'}>{this.state.successMsg}</Alert>}
                    {showError && <Alert color={'success'}>{this.state.errorMsg}</Alert>}
                    
                    <section className={`title-page mt-3`}>
                        <h2>{this.state.tipo}</h2>
                    </section>
                    <Col>                        
                        <Form onSubmit={this.handleSubmit}>
                            <Row>
                                <Col md={4}>
                                    <Label for="name">Disciplina</Label>
                                    <Input
                                        value={this.state.model.name}
                                        id="name"
                                        name="name"
                                        type="text"
                                        required
                                        placeholder="Disciplina"
                                        maxLength={300}
                                        onChange={({ target }) => this.handleInputChange(target)} />
                                </Col>
                                <Col md={4}>
                                    <Label for="knowledgeAreaSelect">Áreas do Conhecimento</Label>
                                    <Input 
                                        type="select" 
                                        name="active" 
                                        id="knowledgeAreaSelect"
                                        value={this.state.knowledgeArea}    
                                        onChange={({target}) => this.handleInputChange(target)}
                                        required
                                    >
                                        <option value="">Selecione...</option>
                                        {
                                            this.state.listknowledgeArea.map((value, index) => {
                                                return (<option value={value.id}>{value.name}</option>)
                                            })
                                        }
                                    </Input> 
                                </Col>
                                <Col md={4}>
                                    <Label for="Status">Status</Label>
                                        <Input 
                                            type="select" 
                                            name="active" 
                                            id="statusSelect"
                                            value={this.state.active}
                                            required    
                                            onChange={({target}) => this.handleInputChange(target)}
                                        >
                                            <option value="">Selecione...</option>
                                            <option value={"1"}>Ativo</option>
                                            <option value={"0"}>Inativo</option>
                                        </Input> 
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label for="learningSteps">Etapas de Ensino</Label>                            
                                    <div>
                                    { this.state.listOfLearningSteps.map((item, index) => { 
                                        return(
                                            <div>
                                                <input {...this.isRequired()} type="checkbox" checked={this.isSelected(item.id)} value={item.id} onChange={({ target }) =>this.handleLearnStepSelect(item.id, target)}/> 
                                                {item.name}
                                            </div>
                                        )
                                    })}   
                                    </div>
                                </Col>
                            </Row>  
                            
                            <Row className="hold-buttons">
                                <Col className="mb-5">
                                    <Button id={"btnSave"}
                                        color="primary" type="submit">
                                        <span>Salvar</span>
                                    </Button>{' '}
                                    <Link to={`/disciplina`}> <Button color="primary">Cancelar</Button> </Link>
                                </Col>
                            </Row>                             
                        </Form>
                    </Col>
                </Container>
            </main>
        );
    }
}

export default withRouter(DisciplinesForm);