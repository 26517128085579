import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import dotenv from 'dotenv';
import {Constants} from './core';

dotenv.config();

export const endpoint =process.env.REACT_APP_CLIENT_ID;

export const adalConfig = {
  tenant: "common",
  clientId: Constants.ADAL.CLIENT_ID,
  redirectUri: Constants.URLS.REDIRECT,
  endpoints: {
    api: 'https://login.microsoftonline.com/',
  },
  cacheLocation: 'sessionStorage'
};
 
export const authContext = new AuthenticationContext(adalConfig);
 
export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
 
export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);